import { PaginatedList } from './../../models/common/paginated-list.class';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlarmConfiguration } from '@app/models/alarms/alarmConfigs.class';
import { StationAlarms, ExpandedStationComment } from "@app/models/alarms/alarms.class";
import { environment } from "@environments/environment";

@Injectable()
export class AlarmService {
  private uri: string = `${environment.routes.apiEndpoint}/alarm`;

  constructor(private http: HttpClient) { }

  public getAlarms(stationIds: string[] = [], stationGroupIds: string[] = [],
    pageSize = 1000, pageIndex = 0) {
    let filters = '';
    filters += `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (stationGroupIds.length) filters += `&StationGroupIds=${stationGroupIds.join('&StationGroupIds=')}`;
    return this.http.get<PaginatedList<StationAlarms>>(`${this.uri}/alarms/monitored?${filters}`);
  }

  getComments(stationIds: string[],
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string | null = null, sortDecending = true) {

    let filters = '';
    filters += `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (stationIds.length) filters += `&StationIds=${stationIds.join('&StationIds=')}`;
    if (sortField) filters += `&SortField=${sortField}&SortDescending=${sortDecending}`;

    return this.http.get<PaginatedList<ExpandedStationComment>>(`${this.uri}/stationComments?${filters}`);
  }

  saveComment(stationId: string, comment: string) {
    return this.http.post(`${this.uri}/stationComments`,
      { comment: comment, stationId: stationId });
  }

  public getAlarmConfig(
    pageSize: number = 10, pageIndex: number = 0,
    sortField: string = 'created', sortDescending: boolean = true,
    isActiveFilter?: boolean
  ) {
    let filters = '';
    filters += `PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (sortField) filters += `&SortByField=${sortField}`;
    if (sortDescending) filters += `&SortDescending=${sortDescending}`;
    if (isActiveFilter) filters += `&IsActive=${isActiveFilter}`;
    return this.http.get<PaginatedList<AlarmConfiguration>>(`${this.uri}/alarmConfigurations?${filters}`);
  }

  public saveAlarmConfig(alarmConfig: AlarmConfiguration) {
    return this.http.post(`${this.uri}/alarmConfigurations`, alarmConfig);
  }

  public resetDeviceAlarms(stationId: string, deviceId: string) {
    return this.http.post(`${this.uri}/alarms/cleanup`, { stationId: stationId, deviceId: deviceId });
  }
}
